// npm
import React, { memo } from 'react'
import { Box, BoxProps, Container, styled } from '@mui/material'
import loadable from '@loadable/component'

// components
const CmsLinkButton = loadable(() => import('@atoms/buttons/CmsLinkButton'))
import TextContent from '@molecules/text/TextContent'

// constants
import { firstSectionSpacing } from '@constants/firstSectionSpacing'

interface SectionTextProps extends BoxProps {
  sectionContent: Queries.DatoCmsTextContent
  bgColor: string
  isFirstSection?: boolean
}

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
}))

const SectionText = ({
  sectionContent,
  bgColor,
  isFirstSection,
  ...props
}: SectionTextProps) => {
  let contentComponent
  switch (sectionContent.model.apiKey) {
    case 'text_content':
      const textContent = sectionContent as Queries.DatoCmsTextContent
      contentComponent = (
        <>
          <TextContent
            smallHeading={textContent.smallHeading}
            mainHeading={textContent.mainHeading}
            leadText={textContent.leadText}
            text={textContent.textNode.childMarkdownRemark.html}
            mb={{ xs: 4, sm: 5, md: 6 }}
            markdownWrapperProps={{ mt: 10 }}
          />
          {textContent.button && textContent.button[0] && (
            <CmsLinkButton button={textContent.button[0]} />
          )}
        </>
      )
      break
  }

  return (
    <Wrapper component="section" overflow="hidden" bgcolor={bgColor} {...props}>
      <Container
        maxWidth="xl"
        sx={{
          pt: isFirstSection ? firstSectionSpacing : { xs: 6, sm: 7, md: 8 },
          pb: isFirstSection ? firstSectionSpacing : { xs: 6, sm: 7, md: 8 },
        }}
      >
        {contentComponent}
      </Container>
    </Wrapper>
  )
}

export default memo(SectionText)
